<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'Bank Deposits': 'Depósitos',
      'Deposit': 'Depositar',
      'Status': 'Estado',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Bank Deposits': 'Depósitos',
      'Deposit': 'Depositar',
      'Status': 'Estado',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        status: {
          value: 'all',
          options: {
            all: 'Todos',
            pending: 'Pendente',
            confirmed: 'Confirmado',
            refused: 'Recusado',
            canceled: 'Cancelado',
          }
        }
      },
      table: {
        heade: [
          '', 'Created in', 'Amount', 'Unit price', 'Total executed', 'Remaining total', '%', 'Status'
        ],
        body: [
          { created: '06/04/2020', amount: 1.111, price: 35, executed: 20, remaining: '00', percent: 100, state: 'Executado integralmente' },
          { created: '06/04/2020', amount: 1.111, price: 35, executed: 20, remaining: '00', percent: 100, state: 'Executado integralmente' },
          { created: '06/04/2020', amount: 1.111, price: 35, executed: 20, remaining: '00', percent: 100, state: 'Executado integralmente' },
          { created: '06/04/2020', amount: 1.111, price: 35, executed: 20, remaining: '00', percent: 100, state: 'Executado integralmente' },
        ],
        loading: false,
        errored: false,
        empty: null,
        modal: false,
      },

      statData: null
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Bank Deposits') }}</h4>
          <button class="btn btn-success" @click="table.modal = true">{{ t('Deposit') }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Status')">
                <b-form-select v-model="filter.status.value" :options="filter.status.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <th>{{ index }}</th>
                    <th>{{ td.created }}</th>
                    <th>{{ td.amount }}</th>
                    <th>{{ td.price }}</th>
                    <th>{{ td.executed }}</th>
                    <th>{{ td.remaining }}</th>
                    <th>{{ td.percent }}</th>
                    <th>{{ td.state }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="table.modal" :title="t('Bitcoin')" centered>
    </b-modal>
  </Layout>
</template>